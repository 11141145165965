<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      tile
      flat
    >
      <v-toolbar
        dark
        flat
        color="white"
      ></v-toolbar>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <h1 class="primary--text hidden-xs-only">무료체험 승인대기</h1>
          <h3 class="primary--text d-flex d-sm-none ml-5">무료체험 승인대기</h3>
          <div class="caption pb-4">
            <!-- <span class="grey--text text--darken-2" style="font-size: 0.8rem">승인대기를 기다리는 회원을 위한 화면입니다.</span> -->
          </div>
          <v-progress-linear
            :indeterminate="isProgress"
            rounded
            reverse
            color="primary"
            :height="isProgress ? 6 : 4"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            flat
            tile
            class="text-h6 px-8 py-6 grey--text text-darken-4"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <span class="ml-5">사무실명</span>
              </v-col>
              <v-col>
                <span style="color: #5B5B5B;">{{ officeName }}</span>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <span class="ml-5">신청일</span>
              </v-col>
              <v-col>
                <span style="color: #5B5B5B;">{{ createdAt }}</span>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="pt-3 pb-6"
            >
              <v-col cols="4">
                <span class="ml-5">신청상태</span>
              </v-col>
              <v-col>
                <span style="color: #5B5B5B;">대기 중</span>
                <!-- <div class="text--primary">
                  승인 여부는 24시간 이내에 회원님의 구글메일
                  <v-btn icon text @click="goGmail">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="primary">mdi-gmail</v-icon>
                      </template>
                      <span>{{ email }}</span>
                    </v-tooltip>
                  </v-btn>
                  로 전달될 것입니다.
                </div> -->
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>

          <v-card
            flat
            tile
            class="text-h6 px-8 pb-6 grey--text text-darken-4"
          >
            <p class="ml-5">
              고객님은 <span style="color: #5B5B5B;">승인대기중</span>입니다.
            </p>
            <p class="ml-5">
              관리자의 <span style="color: #5B5B5B;">승인메일</span>을 받으신 후 이용이 가능합니다.
            </p>
            <p class="ml-5">
              가입시 등록하신
              <span class="primary--text">수신메일</span>
              <v-btn icon text @click="goGmail">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="primary">mdi-gmail</v-icon>
                  </template>
                  <span>{{ email }}</span>
                </v-tooltip>
              </v-btn>
              을 확인하시기 바랍니다.
            </p>
            <p class="ml-5">
              감사합니다.
            </p>
            <p class="ml-5">
              ps. 기타 사항은 <span style="color: #5B5B5B;">고객센터(T 02-521-7505)</span> 또는 <span style="color: #5B5B5B;">sjwfor@gmail.com</span>로 문의하십시오.
            </p>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-progress-linear
            :indeterminate="isProgress"
            rounded
            color="primary"
            :height="isProgress ? 6 : 4"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="8">
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-btn
              x-large
              dark
              depressed
              color="primary"
              class="mr-2 px-12 mt-4"
              @click="goHome"
            >
              확인
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'

export default {
  name: 'Payment01',

  props: {
    source: String
  },

  data: () => ({
    dialog: false,
    officeName: '',
    email: '',
    createdAt: '',
    isProgress: true,
    progressValue: 100
  }),

  created () {
    this.dialog = true
  },

  mounted () {
    // 중요: 백앤드에서 넘어온 쿼리 문자열
    if (this.$route.query) {
      this.email = this.$route.query.email || '지메일'
      this.officeName = this.$route.query.officeName || ''
      this.createdAt = this.$route.query.createdAt || ''
    }

    sleep(1000).then(() => { this.isProgress = false })
  },

  methods: {
    dummy () {
      console.log('dummy test ~')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 구분: 지메일 받은편지 바로가기
    async goGmail () {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/#inbox`
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 확인 버튼 - 초기화면으로 뺀다.
    goHome (to = '') {
      this.dialog = false
      // * [2022.3.15]로그아웃처리 후 초기화면으로 뺀다.
      this.$store.dispatch('LOGOUT').then(() => this.$router.push('/'))
    }
  }
}
</script>
